.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.toggle-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 30px;
  margin-block-end: 30px;
  border-block-end: 1px solid #D3DCEC;
}

.toggle-title {
  min-inline-size: 150px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 0;
}

.toggle {
  background: #FFFFFF;
  border: 2px solid #DEE7F3;
  border-radius: 15px;
  display: flex;
}

.toggle-link {
  block-size: 42px;
  inline-size: 165px;
  border: 2px solid #fff;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  text-decoration: none;
  justify-content: center;

  &.current {
    background: #80C8C1;
    color: #fff;
    font-weight: 600;
  }
}
