.badge {
  --color: inherit;
  --background-color: rgba(49, 57, 95, 0.2);

  background: var(--background-color);
  border-radius: 50px;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--color);

  &.success,
  &.danger,
  &.info {
    --color: #fff;
  }

  &.success {
    --background-color: #80C8C1;
  }

  &.danger {
    --background-color: #E96C5D;
  }

  &.warning {
    --background-color: #FECA05;
  }

  &.info {
    --background-color: #179BD7;
  }
}
