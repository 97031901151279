.field {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 4px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.inputs {
  display: flex;
  gap: 15px;
}

.input {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  block-size: 48px;
  inline-size: 100%;
  padding-inline: 16px;

  &[type="date"] {
    appearance: textfield;
  }

  .error & {
    border-color: #E96C5D;
  }

  &:not(:focus) {
    font-style: italic;
    color: rgba(96, 112, 153, 0.5);
  }

  &:disabled {
    // opacity: 0.5;
  }
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  right: 12px;
  top: 14px;
}

.textarea {
  block-size: 96px;
  padding-block: 14px;
}

.message {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #E96C5D;
}

.select-control.select-control {
  min-inline-size: 94px;
  font-style: normal;
  color: rgba(96, 112, 153, 0.5);

  .disabled & {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.select-menu {
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 8px;
  margin-block-start: 5px;
  padding: 10px 14px;
  min-inline-size: 190px;
}

.select-option.select-option {
  padding-block: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &:not(:last-child) {
    border-block-end: 1px solid #E1E3ED;
  }
}
.select-control-has-value {
    color: #000 !important;
}

.indicator-separator.indicator-separator {
  display: none;
}

.indicators-container.indicators-container {
  margin-right: 15px;
}

.select-single-value.select-single-value {
  color: rgba(96, 112, 153, 0.5);
}

.selected {
  color: #80C8C1;
}

.form-section-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block-end: 15px;
}

.radios {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.radio-option {
  block-size: 48px;
  min-inline-size: calc(25% - 15px);
  inline-size: calc(25% - 15px);

  @media (min-width: 1200px) {
    min-inline-size: 150px;
    inline-size: auto;
  }
}

.checkbox-option {
  block-size: 48px;
}

.radio-option-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  inline-size: 100%;
  block-size: 100%;
  background: #fff;
  border: 1px solid #DEE7F3;
  border-radius: 15px;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 16px;
  cursor: pointer;

  :checked ~ & {
    border-color: #80C8C1;
    border-width: 2px;

    svg {
      fill: #80C8C1;
    }
  }

  :disabled ~ & {
    cursor: not-allowed;
  }
}

.radio {
  position: absolute;
  opacity: 0;
  inline-size: 0;
  block-size: 0;
  pointer-events: none;
}

.checkbox {
  inline-size: 100%;

  &.disabled {
    opacity: 0.4;
  }
}

.isd.isd {
  min-inline-size: 110px;
}

.isd-value {
  display: flex;
  align-items: center;
  gap: 5px;
}
