.wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}

.button {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 12px;
  padding-inline: 16px;
  block-size: 40px;
  inline-size: 72px;
}

.divider {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.overlay {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  background: rgba(49, 57, 95, 0.6);
  z-index: 10;
}

.modal {
  background-color: #fff;
  inline-size: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  padding: 100px 30px 30px;
  overflow: auto;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #62769D;
  margin-bottom: 10px;
}

.times {
  display: flex;
  gap: 20px;
}

.radios {
  flex-direction: column;

  label {
    inline-size: 48px;
    border-radius: 50%;
    min-inline-size: 0;
  }

  div {
    justify-content: center;
    border-radius: 50%;
    flex-direction: column;
    padding: 0;
    text-align: center;
  }

  :checked + div {
    background-color: #80C8C1;
    color: #fff;
    font-weight: bold;
  }

  svg {
    display: none;
  }
}

.cols {
  display: flex;
  gap: 30px;
}

.col {
  flex-grow: 1;

  &:first-child {
    border-right: 1px solid #E5E5E5;
  }
}

.save {
  margin-block: 20px;
}
