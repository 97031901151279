.wrapper {
  position: relative;
}

.field {
  background-color: #EFEFF4;
  border-radius: 12px;
  block-size: 42px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-inline: 13px 0;
  border: 1px solid #fff;
}

.input {
  background: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #31395F;
  border: 0;
  flex-grow: 1;

  &:focus {
    outline: 0;
  }

  &::-ms-clear {
    display: none;
    width : 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.results {
  position: absolute;
  inset-inline: 0;
  inset-block-start: 100%;
  background: #fff;
  border-radius: 10px;
  display: flex;
  list-style-type: none;
  padding: 13px 20px;
  margin: 0;
  flex-direction: column;
}

.result {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding-block: 7.5px;
  cursor: pointer;
}

.no-results {
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-block: 20px 40px;
}

.no-results-message {
  max-inline-size: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.no-results-cta {
  min-block-size: 42px;
  inline-size: 255px;
}

.search,
.cancel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.search {
  block-size: 100%;
  background-color: #80C8C1;
  inline-size: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-start-end-radius: 12px;
  border-end-end-radius: 12px;
}
