.overlay {
  position: fixed;
  inset: 0;
  z-index: 300;
  background-color: rgba(49, 57, 95, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  inline-size: 406px;
  padding: 30px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  inline-size: 80px;
  block-size: 80px;
  margin: 20px 20px 0;
}

.message.message {
  color: #232323;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-inline-size: 286px;
  margin-block: 0 10px;
}

.icon {
  margin-block-end: 20px;
}

.info {
  margin: 0;
  text-align: center;
}

.actions {
  margin-block-start: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  inline-size: 100%;
}

.cta:not(:last-child) {
  inline-size: calc(50% - 10px);
}

.cancel:not(:first-child) {
  inline-size: calc(50% - 10px);
}
