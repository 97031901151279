.wrapper {
  padding-block: 261px 60px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 0 15px;
}

.summary-section {
  display: flex;
  gap: 30px;
  margin-block-end: 40px;
}

.summary {
  inline-size: 100%;
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-block: 0;
  min-block-size: 236px;
}

.summary-icon {
  margin-block-end: 10px;
}

.summary-number {
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  font-style: normal;
  color: #80C8C1;
  min-inline-size: 145px;
}

.summary-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(49, 57, 95, 0.8);
}

.table {
  border-collapse: collapse;
  margin-block-end: 100px;
  inline-size: 100%;
}

.th {
  text-align: start;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: rgba(225, 227, 237, 0.3);
  padding: 10px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-inline-start: 20px;
    padding-inline-start: 20px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-inline-end: 20px;
  }
}

.cell {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 16px 10px 14px;

  &:first-child {
    white-space: break-spaces;
    padding-inline-start: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-inline-end: 20px;
  }

  :nth-child(even) > & {
    background: rgba(225, 227, 237, 0.2);
  }

  :nth-child(odd) > & {
    background: rgba(255, 255, 255, 0.2);
  }
}

.no-results {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-results-message.no-results-message {
  color: #232323;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-inline-size: 286px;
  margin-block: 0 10px;
}

.no-results-icon {
  margin-block-end: 20px;
}

.no-results-info {
  margin: 0;
}
