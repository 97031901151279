.wrapper {
  font-size: 12px;
  // padding-block-start: 200px;

  table {
    inline-size: 100%;
  }

  .tableHeadFixed{
    overflow: auto;
    height: 80Vh;
  }

  thead tr {
    background-color: #80C8C1;
    color: #fff;
    position: sticky;
    top: 0;
  }

  td, th {
    padding: 5px 10px;
    text-align: start;
    line-height: 1.2;
  }

  tbody tr {
    background-color: #fff;

    &:hover {
      background-color: #F5F6FA;
    }
  }

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    white-space: nowrap;
  }

  td svg {
    position: relative;
    inset-block-start: 4px;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #80C8C1;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
  }

  .tableRow {
    cursor: pointer;
    >td {
      max-width: 200px;
      overflow: auto;
    }
  }
}
