.nav {
  display: flex;
  padding: 0;
  margin: 0 0 20px;
}

.item {
  list-style: none;
}

.link {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  background-color: #fff;
  font-size: 16px;
  color: #80C8C1;

  .active & {
    background-color: #80C8C1;
    color: #fff;
  }

  .item:first-child & {
    border-start-start-radius: 10px;
    border-end-start-radius: 10px;
  }

  .item:last-child & {
    border-start-end-radius: 10px;
    border-end-end-radius: 10px;
  }
}
