.wrapper {
  display: flex;
  align-items: center;
}

.label.label {
  font-size: 14px;
  font-weight: 600;
}

.value.value {
  font-size: 12px;
  font-weight: 500;
  color: #31395F;
  margin-inline: 10px;
  display: flex;
  flex-direction: column;
  line-height: 1.1;

  a {
    color: #80C8C1;
  }
}

.select {
  margin-block-start: -3px;
}

.select > div > div {
  border: 0;
}

.select > div > div > div:first-child > div:first-child {
  margin-inline-end: 10px;
}
