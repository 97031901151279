.questionContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.boolQuestion {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
}

.input {
  min-inline-size: 200px;
  gap: 0px !important;
}

.readyForTestMessage {
  text-align: center;
  word-wrap: break-word;
  width: 400px;
  margin: 0 auto 16px;
}

.readyForTestButton {
  text-align: center;
  margin: auto;
}

.padTop {
  padding-top: 16px;
}

.errorMessage {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #E96C5D;
}
