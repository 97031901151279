.wrapper {
  position: relative;
  padding: 0 20px 10px;
  margin-block-end: 20px;

  &:before {
    content: '';
    border: 1px solid var(--green);
    inset-block: 16px 0;
    border-radius: 5px;
    inset-inline: 0;
    position: absolute;
    pointer-events: none;
  }
}

.title {
  display: inline-block;
  position: relative;
  background-color: var(--white);
  color: var(--green);
  padding-inline: 5px;
}
