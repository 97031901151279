.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.trigger {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  block-size: 48px;
  inline-size: 100%;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .error & {
    border-color: #E96C5D;
  }

  &:not(:focus) {
    font-style: italic;
    color: rgba(96, 112, 153, 0.5);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.overlay {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  inset: 0;
  background: rgba(49, 57, 95, 0.6);
  z-index: 10;
}

.modal {
  background-color: #fff;
  inline-size: 625px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  padding: 30px;
  overflow: auto;
}

.tabs {
  display: flex;
  margin-block-end: 30px;
}

.tab {
  inline-size: 100%;
  background: #fff;
  padding: 12px;
  border: 1px solid #DEE7F3;
  cursor: pointer;

  &:first-child {
    border-start-start-radius: 15px;
    border-end-start-radius: 15px;

    &:not(.selected) {
      border-inline-end: 0;
    }
  }

  &:last-child {
    border-start-end-radius: 15px;
    border-end-end-radius: 15px;

    &:not(.selected) {
      border-inline-start: 0;
    }
  }

  &.selected {
    border-color: currentColor;
    background-color: #F2FAF9;
    color: #80C8C1;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 0;
  padding-block: 20px;
  border-block-end: 0.5px solid rgba(123, 128, 152, 0.38);
}

.radios {
  > div {
    gap: 10px;

    > * {
      min-inline-size: calc(20% - 8px);
      inline-size: calc(20% - 8px);
    }
  }
}

.select-control.select-control {
  border-radius: 15px;
  padding-inline: 5px;
  border-color: #62769D;

  &.focused {
    box-shadow: none;
  }

  &.disabled {
    background-color: transparent;
    opacity: 0.5;
  }
}

.indicator-separator.indicator-separator {
  display: none;
}

.indicators-container.indicators-container {
  margin-right: 15px;
}

.select-single-value.select-single-value {
  color: rgba(96, 112, 153, 0.5);
}

.select-option.select-option {
  background: none;

  &.selected {
    background-color: #61B486;
  }

  &:hover:not(.selected) {
    background-color: rgba(#61B486, 0.2);
  }
}
