.wrapper {
  display: flex;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    inset-inline-start: calc(50% - 24px);
    inset-block-start: calc(50% - 24px);
  }
}

.button {
  inline-size: 175px;
  block-size: 48px;
  background: #FFFFFF;
  border: 1px solid #DEE7F3;
  font-weight: 600;

  &.selected {
    background-color: rgba(128, 200, 193, 0.1);
    border-color: #80C8C1;
    color: #80C8C1;
  }

  &:first-child {
    border-start-start-radius: 15px;
    border-end-start-radius: 15px;
  }

  &:last-child {
    border-start-end-radius: 15px;
    border-end-end-radius: 15px;
  }
}
