.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgba(128, 200, 193, 0.08);
  border: 1px dashed #80C8C1;
  border-radius: 8px;
  text-decoration: none;
  flex-direction: column;
  text-align: center;
  gap: 11px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #31395F;
}
