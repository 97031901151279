.prescriptions {
  display: flex;
  gap: 20px;

  > * {
    inline-size: 50%;
  }

  li {
    inline-size: calc(50% - 10px);
  }
}
