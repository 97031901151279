.container {
  max-inline-size: none;
  margin-inline: auto;
  padding-inline: 90px;
  padding-block-end: 1px;

  &.narrow {
    max-inline-size: 960px + 180px;
  }

  &.wide {
    padding-inline: 45px;
  }

  &.widest {
    padding-inline: 4px;
  }
}

.header {
  background-color: #fff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.081);
  position: sticky;
  inset-block-start: 0;
  z-index: 300;
}

.header-container {
  block-size: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.header-col {
  display: flex;

  &:last-child {
    align-items: center;
    justify-content: flex-end;
  }
}

.logo {
  display: block;
  block-size: 25px;
}

.user {
  background: none;
  border: 1px solid #EFEFF4;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.avatar {
  inline-size: 36px;
  block-size: 36px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}

.name {
  margin-inline-end: 22px;
  text-align: start;
}

.role {
  font-size: 10px;
  color: #80C8C1;
}

.select-menu {
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 8px;
  margin-block-start: 5px;
  padding: 10px 14px;
}

.select-option {
  padding-block: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &:not(:last-child) {
    border-block-end: 1px solid #E1E3ED;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  display: flex;
  justify-content: center;
  inline-size: auto;
  margin-inline-end: auto;
}

.main {
  margin-block-start: 40px;
  padding-block-end: 50px;
}

.close {
  margin-inline-start: 20px;
}
