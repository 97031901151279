.wrapper {
  block-size: calc(100vh - 160px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
}

.form {
  flex-grow: 1;
}

.display {
  inline-size: 350px;
  min-inline-size: 350px;
}

.fields {
  display: flex;
  gap: 20px;

  > * {
    flex-grow: 1;
  }
}

.notes-heading {
  font-size: 14px;
  margin-block-end: 0;
  color: #31395F;
}

.notes {
  margin: 0 0 0 30px;
  padding: 0;
  font-size: 12px;
  list-style: disc outside none;
}

.display-heading {
  margin-block: 0;
  font-weight: normal;
}

.days.days {
  font-size: 530px;
  font-weight: bold;
  line-height: 530px;
  margin-block: 0;
  color: #80C8C1;
}

.deliveryDate {
  font-size: 24px;
  font-weight: bold;
  margin-block: 0;
  color: #31395F;
  margin-top: 16px;
}
