.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-block: 0 20px;
  }
  
  .list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .store {
    min-inline-size: calc(33.33% - 13.33px);
    inline-size: calc(33.33% - 13.33px);
    background: #FFFFFF;
    border: 1px solid #D3DCEC;
    border-block-start-width: 10px;
    border-radius: 12px;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    position: relative;
  }
  
  .name {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    margin-block: 8px;
  }
  
  .cta {
    min-block-size: 48px;
    inline-size: 218px;
    max-inline-size: 100%;
  }
  
  .default {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: #80C8C1;
    border-radius: 50px;
    padding: 4px 12px;
    position: absolute;
    inset-block-start: 20px;
    inset-inline-start: 10px;
    color: #FFFFFF;
  }
  
  .message {
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    line-height: 24px;
    margin-block: 0 20px;
    background-color: #d04838;
    display: inline-block;
    padding: 3px 8px;
  }
  
  .city {
    font-size: 18px;
    margin-block: -16px 20px;
  }

  .search {
    background: #FFFFFF;
    border: 1px solid #62769D;
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    outline: 0;
    block-size: 48px;
    inline-size: 100%;
    padding-inline: 16px;
    margin-block-end: 30px;
  }