.wrapper {
  max-inline-size: none;
  margin-inline: auto;
  padding-inline: 90px;
  padding-block-end: 1px;
}

.title {
  display: flex;
  gap: 20px;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field {
  inline-size: 100%;

  &:first-child {
    max-inline-size: 298px;
  }

  &:nth-child(2) {
    margin-block-start: 0px !important;
  }
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 40px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.keratometryWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.keratometrySection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.miresSection { 
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.filterDropdown{
  padding: 5px 5px;
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  block-size: 38px;
 }

 .binocularSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
 }

 .binocularLeftSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
 }

 .binocularRightSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
 }

 .binocularWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inp {
  width: 100%;
  max-width: 400px;

  &:last-of-type {
    margin-block-end: 0.5px;
  }

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}

.customInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  align-self: center;
}

.submit {
  align-self: center;
  margin-block-start: 30px;
  margin-block-end: 100px;
}

.name-upload {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  > :nth-child(2) {
    margin-block-start: 25px;
  }
}

.external-source-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-block-end: 0;
}

.external-field.external-field {
  max-inline-size: 150px;
  min-inline-size: 150px;
}

.external-source {
  display: flex;
  gap: 20px;
  max-inline-size: 400px;
}

.external-source-field {
  margin-block-start: -5px;
}

// pupil reflex section

.pupilWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pupilSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pupilSectionInputGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.anteriorSegmentWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.anteriorSegmentSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ophthalmoscopyWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ophthalmoscopySection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dryTestWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.logo {
  display: none;
}

.signature {
  display: none;
}

.eStampWrapper {
  display: none;
}

.stamp {
  display: none;
}

.extraMargin {
  page-break-after: always;
}

// print styles

@page {
  size: auto;
  margin: 20mm;
}

@media print {
  body {
    background-color: #FFFFFF;
  }

  .logo {
    width: 150px;
    text-align: center;
    align-self: center;
    display: block;
    margin: auto;
    margin-bottom: 14px;
  }

  .eStampWrapper {
    display: block;
    width: 100%;
  }
  
  .stamp {
    width: 100px;
    display: block;
    margin-left: auto;
    margin-top: 18px;
    margin-right: 40px;
  }
  
  .signature {
    display: block;
    margin-left: auto;
    margin-top: 32px;
    width: 180px;
  }
}
