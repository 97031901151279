@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

h3 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  color: #31395F;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 0;
}

.print-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: #31395F;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  padding: 0;
  position: relative;

}

.printButton {
  @media print {
    display: none;
  }

  align-self: center;
  width: 120px !important;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #31395F;
  font-size: 18px;
  line-height: 26px;
  background-color: #f8f8f8;
  border-color: #31395F;
  border-radius: 12px;
}

.logo {
  width: 150px;
  text-align: center;
  align-self: center;
  display: block;
}

.td-center {
  text-align: center;
}

.customerDetails {
  margin: 16px 0px 0px;
}

.customerName {
  padding: 0px;
  border: none;
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.customer {
  margin: 0;
  padding: 0;
}

.eyetestValues {
  margin: 8px 0px 24px;
}

.addOnWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
}

.lensTypeWrapper {
  border: 1px solid #000;
  padding: 2px 8px;
}

.lensTypeLabel {
  text-transform: uppercase;
}

.eStampWrapper {
  align-self: flex-end;
}

.stamp {
  width: 100px;
  display: block;
}

.signature {
  align-self: flex-end;
  margin-top: 18px;
}

.notes {
  inline-size: 300px;
  overflow-wrap: break-word;
  word-break: break-all;
  hyphens: manual;
}

.checkbox {
  accent-color: #80C8C1;
  width: 20px;
  height: 20px;
  transform: translateY(4px);
  margin-right: 8px;
}

.disabled {
  pointer-events: none;
}

.date {
  margin-left: 20px;
}