.button {
  border-radius: 12px;
  inline-size: 286px;
  max-inline-size: 100%;
  min-block-size: 56px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  font-weight: 600;
  box-shadow: var(--box-shadow);
  text-decoration: none;
  cursor: pointer;

  &.loading {
    color: transparent;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.small {
    min-block-size: 42px;
    inline-size: 160px;
  }

  &.extra-small {
    min-block-size: 32px;
    inline-size: auto;
    font-size: 12px;
  }

  &.primary {
    --color: #fff;
    --background-color: #61B486;
    --border-color: #61B486;
    --box-shadow: -5px -5px 20px rgba(250, 251, 255, 0.4), 5px 5px 30px rgba(53, 55, 62, 0.2);

    &:active,
    &:hover {
      --background-color: #80C8C1;
    }
  }

  &.secondary {
    --color: #31395F;
    --background-color: #fff;
    --border-color: #31395F;
    --box-shadow: none;

    &:active,
    &:hover {
      --background-color: rgba(49, 57, 95, 0.03);
    }
  }

  &.tertiary {
    --color: #31395F;
    --background-color: #fff;
    --border-color: #fff;
    --box-shadow: -5px -5px 20px rgba(250, 251, 255, 0.4), 5px 5px 30px rgba(53, 55, 62, 0.2);
  }

  &.error {
    --border-color: #E96C5D;
  }
}

.spinner {
  position: absolute;
  width: 30px;
  height: 30px;

  > * {
    position: absolute;
    inline-size: 100%;
    block-size: 100%;
    border: 4px solid var(--color);
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
