.ordered,
.pending,
.pending_payment,
.processing,
.edited,
.payment_captured,
.holded,
.exchange,
.shipped,
.canceled,
.ready_to_ship,
.shipped_clickpost,
.partially_shipped {
  color: #dba43e;
}

.complete,
.delivered {
  color: #61b486;
}

.closed,
.failed_payment,
.delivery_failed,
.wrong_city,
.wrong_phone,
.wrong_prescription,
.failed_payment,
.on_hold {
  color: #d04838;
}
