.wrapper {
  background-color: rgba(225, 227, 237, 0.2);
  border-radius: 8px;
  padding: 15px 10px;
  color: inherit;
  text-decoration: none;
  display: block;
}

.no-bg {
  background-color: unset !important;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-block: 0 10px;
  padding-block-end: 15px;
  border-block-end: 1px solid #E1E3ED;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
  inline-size: 100%;
}

.item {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  inline-size: 100%;
}

.term {
  font-weight: 500;
}

.def {
  font-weight: 600;
  overflow: hidden;
  block-size: 18px;
  text-overflow: ellipsis;
  text-align: end;
  flex-grow: 1;
}

.highlight {
  color: #80C8C1;
}

.grid {
  display: grid;
  grid: auto-flow / repeat(3, 1fr);
  gap: 15px;
  align-items: center;
}

.grid4 {
  display: grid;
  grid: auto-flow / repeat(4, 1fr);
  gap: 15px;
  align-items: center;
}

.grid2 {
  display: grid;
  grid: auto-flow / repeat(2, 1fr);
  gap: 15px;
  align-items: center;
}

.gridItem2 {
  background-color: rgba(225, 227, 237, 0.2);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  &.wide {
    grid-column-end: span 2;
  }
  &.widest {
    grid-column-end: span 3;
  }
}

.gridItem {
  background-color: rgba(225, 227, 237, 0.2);
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  min-height: 150px;
  justify-content: center;

  &.wide {
    grid-column-end: span 2;
  }
  &.widest {
    grid-column-end: span 3;
  }
}

.metric {
  color: #80C8C1;
  font-size: 30px;
  font-weight: bold;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.growthArrow {
  height: 30px;
}

.changeLabel {
  margin-top: 4px;
}