.button {
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 16px 25px;
  border: 1px solid #31395F;
  border-radius: 8px;
  background: none;
  inline-size: 390px;

  &.loading {
    opacity: 0.5;
  }
}

.users {
  inline-size: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block-end: 30px;
}

.or {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block-end: 30px;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #31395F;
  }
}
