.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: rgba(49, 57, 95, 0.6);
  z-index: 500;
}

.modal {
  inline-size: 474px;
  background-color: #ffff;
  padding-block: 40px;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  max-block-size: 90vh;
  overflow: auto;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-block: 0 32px;
}

.actions {
  display: flex;
  padding-inline: 30px;
  margin-block-start: 30px;
  justify-content: center;
  gap: 15px;
}

.inputs {
  @extend .actions;
}

.input {
  min-block-size: 40px;
  min-inline-size: 260px;
  inline-size: auto;
}

.action {
  min-block-size: 40px;
  min-inline-size: 150px;
  inline-size: auto;
}

.copyIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 8px;
  box-sizing: content-box;
  display: block;
  align-self: flex-end;

  &:hover {
    opacity: 0.7;
    background: rgba(95, 99, 104, 0.1);
    border-radius: 35%;
  }
  &:active {
    opacity: 0.5;
  }
}
