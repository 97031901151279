.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: rgba(49, 57, 95, 0.6);
  z-index: 500;
}

.modal {
  inline-size: 474px;
  background-color: #ffff;
  padding-block: 40px;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  max-block-size: 90vh;
  overflow: auto;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-block: 0 32px;
}

.hours {
  background-color: rgba(225, 227, 237, 0.4);
  padding: 30px;
  display: flex;
}

.actions {
  display: flex;
  padding-inline: 30px;
  margin-block-start: 30px;
  justify-content: center;
  gap: 15px;
}

.action {
  min-block-size: 40px;
  min-inline-size: 150px;
  inline-size: auto;
}

.day-message {
  line-height: 40px;
}
