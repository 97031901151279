.product {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding-block-end: 15px;
  margin-block-end: 17px;
  border-block-end: 1px solid #E1E3ED;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.info {
  display: flex;
  gap: 10px;
  align-items: center;
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin-block: 0;
}

.parentSku {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-block: 0;
  color: rgba(49, 57, 95, 0.7);
}

.sku.sku {
  margin-block: 4px 0;
  color: rgba(49, 57, 95, 0.7);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
}

.message.message {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  margin-block: 0;
  color: #fff;
  padding-inline: 8px;
  background-color: #d04838;
  display: inline-block;
}

.form:not(.selected) {
  display: none;
}

.delivery-promise {
  color: #E96C5D;
  font-weight: bold;
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.dialogClassname {
  min-width: 400px;
  overflow: auto;
}

.printButton {
  border-radius: 24px;
  border: 1px solid rgba(49, 57, 95, 0.7);
  padding: 2px 8px;
  text-align: center;
  font-weight: 600;
  margin: auto;
  background-color: rgba(49, 57, 95, 0.2);

  .printIcon {
    width: 12px;
    margin: 8px 8px 0px 0px;
  }
}
