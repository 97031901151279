.wrapper {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.file.file {
  inline-size: auto;
  block-size: 42px;

  input {
    position: absolute;
    inset-inline-start: -9999px;
  }
}

.image {
  block-size: 42px;
  display: block;
}

.message {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #E96C5D;
}