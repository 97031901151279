.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.upload {
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.upload-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 0;
}

.upload-button {
  min-block-size: 42px;
  inline-size: 250px;

  input {
    position: absolute;
    inset-inline-start: -9999px;
  }
}

.download-button {
  margin-inline-start: auto;
}

.configWrapper {
  display: flex;
  gap: 60px;
  
}

.configItemWrapper {
  border-right: 1px solid #D3DCEC;
  padding: 0px 40px 0px 0px;
}

.configInput {
  max-width: 300px;
  margin-block: 20px;
}

.configAdd {
  display: flex;
  gap: 20px;
  margin-block: 20px;
}

.configSubmitWrapper {
  display: flex;
  gap: 20px;
  margin-block: 20px;
}
