.sku-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 40px 12px;
}

.warn {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #E96C5D;
  color: #fff;
  padding: 10px 25px;
  margin-block-end: 25px;
  border-radius: 10px;

  svg {
    inline-size: 20px;
    block-size: 20px;
  }

  path {
    fill: currentColor;
  }
}

.warn-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.validations {
  color: #E96C5D;
  font-size: 12px;
  display: flex;
  gap: 10px;
  margin-block-start: 10px;
}

.sync {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  flex-direction: column;
  justify-content: center;
}

.sync-message.sync-message {
  font-size: 20px;
  line-height: 20px;
  color: #E96C5D;
  font-weight: 600;
  text-transform: uppercase;
}

.sync-loader {
  inline-size: 350px;
  block-size: 20px;
  background-color: #EFEFF4;
  border-radius: 15px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inline-size: 100%;
    block-size: 100%;
    background-color: #E96C5D;
    border-radius: 15px;
    animation: sync 60s infinite;
  }

  @keyframes sync {
    0% {
      inline-size: 0;
    }
    100% {
      inline-size: 100%;
    }
  }
}
