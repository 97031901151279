.wrapper {
  background-color: #fff;
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 30px;
}

.title {
  margin-block: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.cta {
  display: flex;
  align-items: center;
  gap: 15px;
  background: none;
  border: 0;
  text-decoration: none;
  color: inherit;
}

.footer {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 27px 30px;

  &:not(:first-child) {
    border-block-start: 4px solid rgba(225, 227, 237, 0.4);
  }
}

.message {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-inline-end: auto;
}

.to-store-message {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}

.store {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
