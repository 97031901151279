.wrapper {
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 20px;
  margin-block-end: 20px;
}

.header {
  padding-block-end: 13px;
  margin-block-end: 20px;
  border-block-end: 1px solid #E1E3ED;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.title {
  margin-block: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .auto & {
    gap: 40px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  inline-size: calc(25% - 15px);
  position: relative;

  .wide & {
    @media (min-width: 1100px) {
      inline-size: calc(16.666% - 17px);
    }

    @media (min-width: 1300px) {
      inline-size: calc(12.5% - 18px);
    }
  }

  .auto & {
    @media (min-width: 1100px) {
      inline-size: auto;
    }
  }
}

.term {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.def {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .wrap & {
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    word-wrap: break-word;
  }
}

.link {
  color: #80C8C1;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.copy {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  padding: 5px;
  background: #FFFFFF;
  border: 0;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;
  color: #80C8C1;

  .item:hover & {
    display: flex;
  }
}

.image {
  display: block;
  margin: 0 auto;
  block-size: 100px;
}