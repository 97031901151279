.table {
  border-collapse: collapse;
  margin-block-end: 100px;
  inline-size: 100%;
}

.th {
  text-align: start;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  background: rgba(225, 227, 237, 0.3);
  padding: 10px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-inline-start: 20px;
    padding-inline-start: 20px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-inline-end: 20px;
  }
}

.cell {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 16px 10px 14px;

  &:first-child {
    white-space: nowrap;
    padding-inline-start: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-inline-end: 20px;
  }

  :nth-child(even)>& {
    background: rgba(225, 227, 237, 0.2);
  }

  :nth-child(odd)>& {
    background: rgba(255, 255, 255, 0.2);
  }
}

.editInput {
  padding: 2px 10px;
  border: 1px solid #E1E3ED;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  // width: 100%;
}

.editButton {
  padding: 2px 10px;
  border: 1px solid #E1E3ED;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  background: #61B486;
  color: #fff;
  margin: 0 5px;
  cursor: pointer;
  &.secondary {
    background: #31395F;
  }
}

.no-results {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-results-message.no-results-message {
  color: #232323;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-inline-size: 286px;
  margin-block: 0 10px;
}

.no-results-icon {
  margin-block-end: 20px;
}

.no-results-info {
  margin: 0;
}

.table-skeleton {
  border-collapse: collapse;
  margin-block-end: 100px;
  inline-size: 100%;
}

.table-skeleton-row {
  display: flex;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid #E1E3ED;
}
