.home {
  display: flex;
  gap: 30px;
}

.appointment-wrapper {
  flex: 1 !important;
  overflow: auto;
  display: flex;
}

.cards{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  flex: 3;
}

.overlay {
  position: fixed;
  inset: 0;
  background: rgba(49, 57, 95, 0.6);
  z-index: 310;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  min-inline-size: calc(50% - 15px);
  max-inline-size: calc(50% - 15px);
  max-height: clamp(390px, calc(42vh - 8px), 450px);
  // overflow: auto;
  padding: 22px 20px 20px;
  align-items: stretch;
  flex: 0 1 auto;

  // @media (min-width: 1600px) {
  //   min-inline-size: calc(33.33% - 20px);
  //   max-inline-size: calc(33.33% - 20px);
  // }

  // .appointment-prescription-wrapper & {
  //   min-inline-size: calc(50% - 15px);
  //   max-inline-size: calc(50% - 15px);

  //   @media (min-width: 1600px) {
  //     min-inline-size: calc(33.33% - 15px);
  //     max-inline-size: calc(33.33% - 15px);
  //   }

  //   &.highlighted {
  //     min-inline-size: calc(100% - 15px);
  //     max-inline-size: calc(100% - 15px);

  //     @media (min-width: 1600px) {
  //       min-inline-size: calc(66.66% - 15px);
  //       max-inline-size: calc(66.66% - 15px);
  //     }
  //   }
  // }

  &.highlighted {
    background-color: #80C8C1;
  }

  .overlay + & {
    z-index: 330;
  }
}

.cardContent {
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  margin-block-end: 20px;
  padding-block-end: 17px;
  border-block-end: 1px solid #E1E3ED;
  min-block-size: 115px;

  svg {
    min-inline-size: 10px;
    margin-inline-start: 30px;
  }
}

.title-description-wrapper {
  margin-inline-end: auto;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #31395F;
  margin-block: 0 5px;

  .highlighted & {
    color: #fff;
  }
}

.description.description {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #31395F;
  margin-block: 0;

  .highlighted & {
    color: #fff;
  }
}

.cols {
  display: flex;
  gap: 18px;
  margin-block-start: 20px;
  overflow: auto;
}

.cta {
  min-inline-size: 144px;
  max-inline-size: 144px;
}

.newest-prescriptions {
  display: flex;
  gap: 18px;
  min-inline-size: calc(100% - 118px);
  // inline-size: calc(100% - 118px);
  
}

.newest-prescription {
  min-inline-size: calc(50% - 31px);
  // inline-size: calc(50% - 31px);
}

.newest-orders {
  display: flex;
  gap: 18px;
  // inline-size: calc(100% - 162px);
  // overflow: auto;
}

.newest-order {
  max-inline-size: calc(50% - 9px);
  inline-size: 90%;

  .highlighted & {
    background-color: rgba(255, 255, 255, 0.8);
    // min-inline-size: calc(25% - 13.5px);
    // inline-size: calc(25% - 13.5px);
  }
}

.statistics {
  block-size: 180px;
  display: flex;
  inline-size: 100%;
}

.statistic {
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:first-child {
    border-inline-end: 1px solid #E1E3ED;
  }
}

.statistic-number {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #80C8C1;
  font-style: normal;
  min-inline-size: 120px;
}

.statistic-label {
  font-size: 12px;
  line-height: 28px;
}

.config {
  flex-grow: 1;
  margin-block-start: 0;
}

.user {
  inline-size: 100%;
}

.recent.recent {
  font-size: 18px;
  margin-block-end: 10px;
  padding-block-end: 10px;
  border-block-end: 1px solid #E1E3ED;
  color: #fff;
}

.todays-appointments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.todays-appointment {
  // inline-size: 90%;

  .highlighted & {
    background-color: rgba(255, 255, 255, 0.8);
    // min-inline-size: calc(25% - 13.5px);
    // inline-size: calc(25% - 13.5px);
  }
}

.appointment-ctas {
  display: flex;
  gap: 18px;
  margin-block-start: 20px;
}

.viewToggle {
  background-color: #fff;
  text-decoration: underline;
  color: #80C8C1;
  border: none;
  box-shadow: none;
  margin-left: auto;
  padding: 0;
  inline-size: unset !important;
}

.flex-width-card {
  max-inline-size: fit-content !important;
}

.card-reset {
  inline-size: unset;
  min-inline-size: unset;
  max-inline-size: unset;
  max-height: clamp(390px + 390px + 30px, calc(100vh - 128px), 100vh);
  height: auto;
  display: flex;
  overflow: auto;

  .header {
    min-block-size: unset;
  }
}

.card-width-reset {
  inline-size: unset !important;
  min-inline-size: unset !important;
  max-inline-size: unset !important;
}

.tabularSummaryWrapper {
  position: relative;
}

.down-arrow {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 0;
  height: 20px;
  border: 2px solid;
  border-radius: 2px;
  animation: jumpInfinite 1.5s infinite;
  color: #80C8C1;

  &.fade {
    display: none;
  }
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 4px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.or {
  text-align: center;
  border: 1px solid #31395F;
  height: 1px;
  margin: 16px 0px;
}

.loginForm {
  display: flex;
  gap: 32px;
  align-items: flex-start;
}

.formButton {
  align-self: flex-start;
  min-inline-size: 180px;
  max-inline-size: 180px;

  &.center {
    align-self: center;
  }
}

.users-dropdown {
  inline-size: 100%;
  height: 80px;
}

.slots-exhausted {
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  color: #E96C5D !important;
  font-weight: bold !important;
}

.slots-exhausted-link {
  color: #80C8C1;
  text-decoration: underline;
}
