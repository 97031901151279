@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

:root, body {
  background-color: #F5F5F5;
  margin: 0;
}

:global(*) {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

.wrapper {
  &, p {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    color: #31395F;
    font-size: 16px;
    line-height: 26px;
  }
}
