.wrapper {
  inline-size: 100%;
  block-size: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:before {
    position: absolute;
    inset: 0;
    background: rgba(49, 57, 95, 0.15);
    content: "";
    z-index: 1;
  }
}

.background {
  position: absolute;
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
  object-position: center;
}

.card {
  inline-size: 510px;
  background-color: #fff;
  box-shadow: -5px -5px 20px rgba(250, 251, 255, 0.4), 5px 5px 30px rgba(53, 55, 62, 0.2);
  border-radius: 12px;
  padding: 60px 45px 45px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  text-align: center;
  align-items: center;
}

.logo {
  margin-block-end: 30px;
  align-self: center;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0 0 15px;
  text-transform: uppercase;
}

.description {
  margin-block-end: 30px;
}
