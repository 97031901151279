// .wrapper {
//     padding-block-start: 0px;
//   }
  
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-block: 0 15px;
  }

  .filterSection{
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 10px;
    align-items: center;
    border-radius: 10px;
  }

  .filterDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 500;
  }

  .filterLabel{
    font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
  }

   .filterDropdown{
    padding: 5px 5px;
    background: #FFFFFF;
    border: 1px solid #62769D;
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    block-size: 38px;
   }
  
  .orders :global(.infinite-scroll-component) {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-block-end: 100px;
  }
  
  .order {
    min-inline-size: calc(33.33% - 13.33px);
    max-inline-size: calc(33.33% - 13.33px);
    background-color: #fff;
    border: 1px solid #D3DCEC;
  }
  
  .pendingOrder {
    @extend .order;
    border: 1px solid red;
  }
  
  
  .no-results {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .no-results-message.no-results-message {
    color: #232323;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    max-inline-size: 286px;
    margin-block: 0 10px;
  }
  
  .no-results-icon {
    margin-block-end: 20px;
  }
  
  .no-results-info {
    margin: 0;
  }
  
  .table-wrapper {
    overflow: auto;
    margin-block-end: 40px;
  }
  
  .table {
    margin-block-end: 0;
  }
  
  .th {
    white-space: inherit;
  }
  
  .load {
    margin-block-end: 40px;
    
  }
  
  .link {
    color: #80C8C1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
  .error-info {
    color: #fc766a;
    font-size: 11px;
    text-align: center;
  }
  
  .invoice-form {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    margin-block-start: 30px;
    flex-wrap: wrap;
  
    button {
      margin-block-start: 18px;
    }
  }

  // .twoButton{
  //   display: flex;
  // }

  .acceptbtn {
      padding: 0px; 
      max-inline-size: 55%;
      min-block-size: 45px;
      margin: 5px; 
      background-color: rgb(5, 138, 71);
      border: rgb(5, 138, 71) ;
      &:hover {
        background-color: rgb(117, 148, 117);
      }
   }

   .rejectbtn {
    padding: 0px; 
    max-inline-size: 55%;
    min-block-size: 45px;
    margin: 5px; 
    background-color: rgb(214, 63, 63); 
    border: rgb(214, 63, 63) ;
    &:hover {
      background-color: rgb(206, 124, 124);
    }
 }

   .loader h3{
      margin: auto;
      width: fit-content;
   }

   