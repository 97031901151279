.button {
  block-size: 42px;
}

.back {
  inline-size: 89px;
  min-block-size: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
}

.modal {
  position: fixed;
  inset: 0;
  z-index: 2000;
  background-color: #F5F5F5;
  overflow: auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.card {
  border: 1px solid #D3DCEC;
  border-radius: 8px;
  background: #fff;
  padding: 9px 15px 15px;
  margin: 0;
  inline-size: calc(33.33% - 13.33px);
}

.title-cta-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 10px;
  padding-block-end: 9px;
  border-block-end: 1px solid #E1E3ED;
}

.cta.cta {
  inline-size: 89px;
  min-block-size: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.lens-row {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-block: 0;
}

.lens-type-value {
  font-weight: 600;
}

.name {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  block-size: 21px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-inline-size: calc(100% - 90px);
}

.table {
  border-collapse: collapse;
  inline-size: 100%;

  th {
    font-weight: 700;
    font-size: 9px;
    line-height: 14px;
  }

  td {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    &:first-child {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
    }
  }

  td, th {
    padding-block: 5px;

    &:nth-child(2),
    &:nth-child(3) {
      inline-size: 70px;
      text-align: center;
    }

    &:last-child {
      border-inline-start: 1px solid #E1E3ED;
    }
  }
}
