@import "react-datepicker/dist/react-datepicker.css";

.wrapper {

  display: flex;
  flex-direction: column;
  gap: 40px;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
  }

  .link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #80C8C1;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
  }

}

.outlet-wrapper {
  flex-grow: 1;
}

.filterBar,
.createBar {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin: 16px 0px;
}

.search {
  align-self: center;
  margin: auto;
  max-inline-size: 50%;
  margin-bottom: 16px;
}

.searchLabel {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.search-field {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  block-size: 48px;
  inline-size: 100%;
  padding-inline: 43px 16px;

  .summary & {
    block-size: 40px;
    padding: 7px 16px 7px 40px;
  }

  &:not(:focus) {
    font-style: italic;
    color: rgba(96, 112, 153, 0.5);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;;
}

.filterBar {
  margin: 20px 0px;
}

.card {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 16px;
  width: 600px;
}

.row {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
}

.value {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

// appointments styles

.filterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
}

.date {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #31395F;
  border-radius: 16px;
  padding-right: 50px;
}

.filterInput {
  min-inline-size: 120px;
}

.icon {
  position: absolute;
  right: 20px;
  top: 35px;
}

.actionWrapper {
  display: flex;
  gap: 16px;
}

.actionButton {
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 18px;
  margin: 0 5px;
  cursor: pointer;
}


.pagination {
  // background-color: #fff;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationButtonWrapper {
  display: flex;
  block-size: 66px;
  padding-inline: 30px;
  gap: 30px;
}

.pageNumber {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 8px;
  background: none;
  margin: auto;
  background-color: #80C8C1;
  color: #fff;
}

.paginationButton {
  padding: 0;
  border: 0;
  background: none;
}


.search {
  position: relative;
  margin-block-end: 14px;

  .summary & {
    margin-block-end: 0;
    min-inline-size: 200px;
    max-inline-size: 200px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(15%);
    inset-inline-start: 12px;
  }
}

.search-field {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  block-size: 48px;
  inline-size: 100%;
  padding-inline: 43px 16px;

  .summary & {
    block-size: 40px;
    padding: 7px 16px 7px 40px;
  }

  &:not(:focus) {
    font-style: italic;
    color: rgba(96, 112, 153, 0.5);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.appointmentStatus {
  text-transform: capitalize;
  padding: 4px 8px;
  max-inline-size: 100px;
  text-align: center;
  // border: 1px solid #31395F;
  border-radius: 16px;
  &.cancelled {
    background-color: #FBF9F4;
    color: #BC4141;
    border: 1px solid #BC4141;
  }
  &.confirmed {
    background-color: #EBF9F1;
    color: #41BC63;
    border: 1px solid #41BC63;

  }
  &.booked {
    background-color: #FBF9F4;
    color: #BC9241;
    border: 1px solid #BC9241;
  }
  &.missed {
    background-color: #dfdfdf;
    color: #31395F;
    border: 1px solid #31395F;
  }
}

.dialogInputWrapper {
  margin-bottom: 16px;
}

.dialogButtonWrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
}