.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  inline-size: 100%;

  + .fields {
    margin-block-start: 20px;
  }
}

.warehouse-type {
  min-inline-size: 170px;
}

.address-code {
  inline-size: 120px;
}

.address {
  inline-size: 300px;
}

.delivery-area {
  inline-size: 300px;
}

.directions {
  inline-size: 400px;
}

.countries {
  inline-size: 250px;
}

.map {
  inline-size: 350px;
}

.city {
  inline-size: 120px;
}

.click-and-collect,
.eye-test-booking {
  inline-size: auto;
}

.dayInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  gap: 30px;
  margin-block-end: 20px;
  .dayInputLabel {
    margin-right: 20px;
    width: 100px;
  }

  .copyIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 8px;
    box-sizing: content-box;
    display: block;

    &:hover {
      opacity: 0.7;
      background: rgba(95, 99, 104, 0.1);
      border-radius: 35%;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.operationalHoursLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin: 16px 0px;
}

.error-info {
  color: #fc766a;
  font-size: 11px;
  text-align: center;
}