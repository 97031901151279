.wrapper {
  margin-block: 20px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-block: 0 10px;
}

.cols {
  display: flex;
  gap: 10px;
}

.col {
  inline-size: 100%;
  background-color: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 20px;
}

.heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-block: 0 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid #E1E3ED;
}

.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  inline-size: 100%;
}

.field {
  min-inline-size: calc(50% - 10px);
}

.corridorField {
  inline-size: 200px;
  padding: 12px 0px 0px;
}
