@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

/*   Open state of the dialog  */
dialog[open] {
  opacity: 1;
  transform: scaleY(1);
  min-width: 250px;
}

/*   Closed state of the dialog   */
dialog {
  opacity: 0;
  transform: scaleY(0);
  border-radius: 12px;
  border: 1px solid #31395F;
  overflow: auto;
  transition:
    opacity 0.5s ease-out,
    transform 0.5s ease-out,
    overlay 0.5s ease-out allow-discrete,
    display 0.5s ease-out allow-discrete;
  /* Equivalent to
  transition: all 0.5s allow-discrete; */
}

/*   Before-open state  */
/* Needs to be after the previous dialog[open] rule to take effect,
    as the specificity is the same */
@starting-style {
  dialog[open] {
    opacity: 0;
    transform: scaleY(0);
  }
}

/* Transition the :backdrop when the dialog modal is promoted to the top layer */
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0%);
  transition:
    display 0.5s allow-discrete,
    overlay 0.5s allow-discrete,
    background-color 0.5s;
  /* Equivalent to
  transition: all 0.5s allow-discrete; */
}

dialog[open]::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

/* This starting-style rule cannot be nested inside the above selector
because the nesting selector cannot represent pseudo-elements. */

@starting-style {
  dialog[open]::backdrop {
    background-color: rgba(0, 0, 0, 0%);
  }
}

.closeWrapper {
  height: 36px;
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 16px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-family: Poppins, sans-serif;
}