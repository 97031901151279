.prescriptions {
  display: flex;
  gap: 20px;
}

.slots {
  display: flex;
  gap: 10px;
  inline-size: 100%;
  flex-wrap: wrap;
}

.get-prescriptions {
  flex-wrap: wrap;
}

.events {
  > *:first-child {
    block-size: 54px !important;
  }
}
