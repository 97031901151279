.wrapper {
  padding-block-start: 250px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 0 15px;
}

.orders :global(.infinite-scroll-component) {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-block-end: 100px;
}

.order {
  min-inline-size: calc(33.33% - 13.33px);
  max-inline-size: calc(33.33% - 13.33px);
  background-color: #fff;
  border: 1px solid #D3DCEC;
}

.pendingOrder {
  @extend .order;
  border: 1px solid red;
}


.no-results {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-results-message.no-results-message {
  color: #232323;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  max-inline-size: 286px;
  margin-block: 0 10px;
}

.no-results-icon {
  margin-block-end: 20px;
}

.no-results-info {
  margin: 0;
}

.table-wrapper {
  overflow: auto;
  margin-block-end: 40px;
}

.table {
  margin-block-end: 0;
}

.th {
  white-space: inherit;
}

.load {
  margin-block-end: 40px;
}

.link {
  color: #80C8C1;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.error-info {
  color: #fc766a;
  font-size: 11px;
  text-align: center;
}

.invoice-form {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-block-start: 30px;
  flex-wrap: wrap;

  button {
    margin-block-start: 18px;
  }
}

.twoButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.acceptButton {
  padding: 10px;
  background-color: #22C55E;
  min-block-size: unset !important;
  border: #22C55E;
  min-inline-size: 80px;
  &:hover {
    background-color: #22c55ea9;
  }
}

.rejectButton {
  min-inline-size: 80px;
  padding: 10px;
  min-block-size: unset !important;
  background-color: #FF5630;
  border: #FF5630;

  &:hover {
    background-color: #ff5630a9;
  }
}
.lastCallStatus {
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 50px;  
  &.success {
    color: #22C55E;
  }
  
  &.failure {
    color: #FF5630;
  }
}
