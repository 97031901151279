.wrapper {
  padding-block-end: 30px;
  border-block-end: 1px solid #E1E3ED;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user {
  display: flex;
  gap: 15px;
  align-items: center;
}

.image {
  background: #EFEFF4;
  border-radius: 13.3333px;
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 60px;
  block-size: 60px;
}

.details {
  min-inline-size: 200px;
}

.name.name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-block: 0 4px;
}

.store.store {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-block: 0;
}

.availability.availability {
  inline-size: auto;
  white-space: nowrap;
}
