.sections,
.events,
.section,
.event {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sections {
  background-color: #fff;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background: rgba(225, 227, 237, 0.4);
  padding: 15px 30px;
  margin-block: 0;
}

.event {
  padding: 30px 0;
  margin-inline: 30px;

  &:not(:last-child) {
    border-bottom: 1px solid #E1E3ED;
  }
}

.event-summary {
  display: flex;
  align-items: center;
}

.time {
  inline-size: 210px;
}

.icon {
  inline-size: 40px;
  block-size: 40px;
  border-radius: 50%;
  background: #80C8C1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 15px;
}

.name-location {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-weight: 500;
  text-transform: capitalize;
}

.location {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.trigger {
  margin-inline-start: auto;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
}

.event-details {
  display: flex;
  gap: 50px;
  margin-block-start: 30px;
  padding-block-start: 30px;
  border-block-start: 1px solid #E1E3ED;
}

.actions {
  min-inline-size: 210px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-action {
  min-block-size: 42px;
  inline-size: 182px;
}

.cancel {
  background: transparent;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 11px;
  border: 0;
  cursor: pointer;
  color: #E52D42;
  font-size: 14px;
  padding: 12px;
  inline-size: 100px;
  border-radius: 8px;

  &:hover {
    background-color: #feafb83a;
  }
}

.infos {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.info-item {
  inline-size: 245px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-block: 0;
}

.info-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.info-value {
  font-weight: 600;

  .info-item:first-child & {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 26px;
  }
}

.status {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 7px;

  &.complete {
    color: #61B486;
  }

  &.no-show {
    color: #E52D42;
  }
}
