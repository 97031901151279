.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.selector {
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 30px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 0 30px;
}

.cols {
  display: flex;
  gap: 30px;
}

.col {
  flex-grow: 1;

  &:last-child {
    border-inline-start: 1px solid #E1E3ED;
    padding-inline-start: 30px;
    min-inline-size: 302px;
    max-inline-size: 302px;
  }
}

.day {
  padding-block: 15px;
  display: flex;
  inline-size: 100%;

  &:first-child {
    padding-block-start: 0;
  }

  &:last-child {
    padding-block-end: 0;
  }

  &:not(:last-child) {
    border-block-end: 1px solid #E1E3ED;
  }
}

.checkbox-day {
  display: flex;
  gap: 12px;
  align-items: center;
  inline-size: 94px;

  .disabled & {
    opacity: 0.4;
  }

  .checked & {
    block-size: 40px;
  }
}

.checkbox-input {
  position: absolute;
  inset-inline-start: -9999px;
}

.checkbox {
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  inline-size: 14px;
  block-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  .checked & {
    background-color: #4DACB7;
  }
}

.day-label {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-block: 0;
  text-transform: uppercase;

  .checked & {
    font-weight: 600;
  }
}

.times {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;

  .disabled & {
    opacity: 0.4;
  }
}

.time {
  display: flex;
  gap: 15px;
  align-items: center;
}

.day-message {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: rgba(49, 57, 95, 0.5);

  .disabled & {
    opacity: 0.4;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  cursor: pointer;

  .disabled & {
    opacity: 0.4;
  }
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  margin-inline-start: auto;
  cursor: pointer;
  block-size: 40px;
  padding: 0;

  .disabled & {
    opacity: 0.4;
  }
}

.loader {
  min-inline-size: 150px;
}

.description.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-block: -15px 15px;
}

.week-trigger {
  min-block-size: 40px;
  inline-size: 100%;
  margin-block-start: 15px;
}
