.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #fff;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 20px;
  inline-size: 100%;

  > svg {
    &:first-child {
      min-inline-size: 44px;
    }

    &:last-child {
      min-inline-size: 5px;
    }
  }
}

.content {
  text-align: start;
  margin-inline-end: auto;
}

.title {
  font-weight: 700;
}

.description {
  margin-block-start: 2px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}
