  
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-block: 0 32px;
  }
  
  .actions {
    display: flex;
    padding-inline: 30px;
    margin-block-start: 30px;
    // justify-content: center;
    gap: 15px;
  }
  
  .inputs {
    @extend .actions;
  }
  
  .input {
    min-block-size: 40px;
    min-inline-size: 260px;
    inline-size: auto;
  }
  
  .action {
    min-block-size: 40px;
    min-inline-size: 150px;
    inline-size: auto;
  }
  
  .copyIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 8px;
    box-sizing: content-box;
    display: block;
    align-self: flex-end;
  
    &:hover {
      opacity: 0.7;
      background: rgba(95, 99, 104, 0.1);
      border-radius: 35%;
    }
    &:active {
      opacity: 0.5;
    }
  }
  