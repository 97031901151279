.section {
  &:not(:last-child) {
    border-block-end: 1px solid #D3DCEC;
    margin-block-end: 20px;
  }
}

.children {
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.child {
  margin: 0;
  padding: 0;
}

.child-link {
  text-decoration: none;
  padding: 8px 20px;
  background-color: #fff;
  border-radius: 20px;
  font-size: 14px;
  color: inherit;
}

.prescriptions {
  @media screen and (min-width: 1100px) {
    display: flex;
    gap: 20px;
  }
}

.additional-details {
  @media screen and (min-width: 1100px) {
    min-inline-size: 40%;
  }

  &:first-child:last-child {
    flex-grow: 1;
  }
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  inline-size: 100%;
}

.no-results {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  padding-block: 20px 40px;
}

.no-results-message {
  max-inline-size: 200px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

.no-prescriptions {
  font-size: 12px;
  font-weight: normal;
  color: #80C8C1;
  padding-inline-start: 10px;
}

.link {
  color: #80C8C1;
  text-decoration: none;
  font-weight: 600;
}

.error-info {
  color: #fc766a;
  font-size: 11px;
  text-align: center;
}

