.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-block: 40px 15px;
}

.calendar-container {
  display: flex;
  gap: 20px;
}

.calendar {
  inline-size: 100%;

  :global(.react-datepicker) {
    inline-size: 100%;
    background: #FFFFFF;
    border: 1px solid #D3DCEC;
    border-radius: 12px;
    padding: 50px 40px;
    margin-block-start: 0;
  }

  :global(.react-datepicker__month-container) {
    float: none;
  }

  :global(.react-datepicker__month) {
    margin: 0;
  }

  :global(.react-datepicker__header) {
    padding: 0;
    background: none;
    border-block-end: 0;
    text-align: start;
    position: static;
  }

  :global(.react-datepicker__day-names) {
    display: flex;
    margin-block-end: 8px;
  }

  :global(.react-datepicker__day-name) {
    inline-size: 100%;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: rgba(49, 57, 95, 0.5);
    margin: 0;
  }

  :global(.react-datepicker__week) {
    display: flex;
  }

  :global(.react-datepicker__day) {
    inline-size: 100%;
    margin: 13px 0;

    &:hover {
      background: transparent;
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: transparent;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 20px;
}

.day {
  position: relative;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.react-datepicker__day--keyboard-selected) & {
    background-color: #FFFFFF;

    &:before {
      position: absolute;
      inset-inline-start: 50%;
      inset-block-start: 50%;
      transform: translate(-50%, -50%);
      inline-size: 41px;
      block-size: 41px;
      content: "";
      border-radius: 50%;
      background-color: #80C8C1;
    }
  }
}

.day-label {
  position: relative;
}

.arrow {
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.month {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.slots-col {
  inline-size: 100%;
  position: relative;
}

.slots {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  inset-inline: 0;
  max-block-size: 100%;
  overflow: auto;
}

.slot {
  inline-size: calc(33.33% - 6.66px);
}

.slot-label {
  block-size: 64px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D3DCEC;
  border-radius: 12px;

  :checked ~ & {
    background: #F9FFFE;
    border: 3px solid #80C8C1;
  }
}

.slot-input {
  position: absolute;
  inset-inline-start: -9999px;
}

.no-slots {
  margin: auto;
}

.submit {
  margin-block-start: 40px;
  display: flex;
  justify-content: center;
}

.success {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  background-color: #80C8C1;
  z-index: 400;
}

.success-header {
  background: #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.081);
  min-block-size: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}

.success-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-block: 40px 30px;
}

.th {
  display: flex;
  align-items: center;
  gap: 18px;
  font-weight: 600;
  min-inline-size: 232px;
  padding-block: 7px;
}

.success-item-icon {
  inline-size: 18px;
  block-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.td {
  min-inline-size: 232px;
  text-align: end;
  padding-block: 7px;
}

.cta {
  margin-block-start: 40px;
}
