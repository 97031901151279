.nav {
    display: flex;
    padding: 0;
    margin: 0 0 20px;
  }
  
  .item {
    list-style: none;
  }
  
  .link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #fff;
    font-size: 16px;
    color: #16537e;
    border: 1px solid #16537e;
  
    .active & {
      background-color: #16537e;
      color: #fff;
    }
  
    .item:first-child & {
      border-start-start-radius: 10px;
      border-end-start-radius: 10px;
    }
  
    .item:last-child & {
      border-start-end-radius: 10px;
      border-end-end-radius: 10px;
    }
  }
  