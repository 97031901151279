.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field {
  inline-size: 100%;

  &:first-child {
    max-inline-size: 298px;
  }
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  align-self: center;
}

.submit {
  align-self: center;
  margin-block-start: 30px;
  margin-block-end: 100px;
}

.name-upload {
  display: flex;
  align-items: flex-start;
  gap: 20px;

  > :nth-child(2) {
    margin-block-start: 25px;
  }
}

.external-source-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-block-end: 0;
}

.external-field.external-field {
  max-inline-size: 150px;
  min-inline-size: 150px;
}

.external-source {
  display: flex;
  gap: 20px;
  max-inline-size: 400px;
}

.external-source-field {
  margin-block-start: -5px;
}
