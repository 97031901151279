.wrapper {
  font-size: 12px;
  // padding-block-start: 200px;

  table {
    inline-size: 100%;
  }

  .tableHeadFixed{
    overflow: auto;
    height: 80Vh;
  }

  thead tr {
    background-color: #80C8C1;
    color: #fff;
    position: sticky;
    top: 0;
  }

  td,
  th {
    padding: 5px 10px;
    text-align: start;
    line-height: 1.2;
  }

  tbody tr {
    background-color: #fff;

    &:hover {
      background-color: #F5F6FA;
    }
  }

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    white-space: nowrap;
  }

  td svg {
    position: relative;
    inset-block-start: 4px;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #80C8C1;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
  }

  .tableRow {
    cursor: pointer;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.submit {
  inline-size: 390px;
}

.fieldsWrapper {
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
  padding: 20px;
  align-self: stretch;
}

.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  inline-size: 100%;
  margin-block-end: 16px;
}

.field {
  inline-size: calc(33.33% - 13.333px);
}

.formSectionTitle {
  align-self: flex-start;
}

.warehouseMultiSelect {
  min-width: 200px;
}

.warehouseList {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 1100px;
}

.warehouseListItem {
  display: flex;
  border: 1px solid #ccc;
  padding: 8px 16px ;
  gap: 8px;
  background: #cccaca63;
  border-radius: 1rem;
}

.delete {
  color: #F44336;
  cursor: pointer;
  background: none;
  border: none;
}

.updatePasswordButton {
  align-self: flex-end;
}

.active-checkbox {
  inline-size: auto;
  margin: auto 0 0;
}

.assign-all-checkbox {
  align-items: flex-start;
}