.form {
  display: flex;
  flex-direction: column;
  margin-block-end: 100px;
}

.get-upload {
  display: flex;
  gap: 20px;
  align-items: center;
}

.lens-select {
  margin-block-end: 20px;
  display: flex;
  gap: 20px;
}

.child-select {
  min-inline-size: 200px;
}

.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  inline-size: 100%;
}

.field.field {
  inline-size: calc(33.33% - 13.333px);
}

.comment.comment {
  inline-size: 100%;
}

.submit {
  align-self: center;
  margin-block-start: 40px;
}

.promise.promise {
  font-size: 14px;
  color: #80C8C1;
  margin-block: 10px -50px;
}

.external-source {
  margin-block-start: 20px;
}

.external-field.external-field {
  max-inline-size: 150px;
  min-inline-size: 150px;
}

.external-source {
  display: flex;
  gap: 20px;
  max-inline-size: 400px;
}

.external-source-field {
  margin-block-start: -5px;
}
