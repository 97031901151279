.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.appointments {
  position: relative;
}

.tabs {
  display: flex;
  border-start-start-radius: 12px;
  border-start-end-radius: 12px;
  background-color: #fff;
  block-size: 66px;
  padding-inline: 30px;
  gap: 30px;
}

.tab {
  padding: 0;
  border: 0;
  background: none;

  &.current {
    font-weight: 600;
    color: #4DACB7;
    border-bottom: 2px solid;
  }
}

.pagination {
  border-end-start-radius: 12px;
  border-end-end-radius: 12px;
  background-color: #fff;
  margin-top: 20px;
}

.paginationButtonWrapper {
  display: flex;
  block-size: 66px;
  padding-inline: 30px;
  gap: 30px;
  
  margin-left: auto;
}

.paginationButton {
  padding: 0;
  border: 0;
  background: none;
}
