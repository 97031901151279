.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.submit {
  inline-size: 390px;
}

.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  // inline-size: 100%;
  // justify-content: center;
  &.buttonsWrapper {
    justify-content: center;
  }
}

.field {
  min-inline-size: calc(33.33% - 13.333px);
  max-inline-size: calc(50% - 13.333px);
}

.customer {
  @media (min-width: 1200px) {
    display: flex;
    gap: 20px;
  }

  >* {
    flex-grow: 1;
  }
}

.verifyForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.verify-button {
  align-self: center;
  inline-size: calc(33.33% - 13.333px);
}

.otp-input {
  inline-size: calc(33.33% - 13.333px);
  align-self: center;
}