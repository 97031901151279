.language {
  margin-block: 60px 15px;
}

.field {
  inline-size: 100%;
}

.or {
  position: relative;
  margin-block: 20px;

  &:before,
  &:after {
    position: absolute;
    block-size: 1px;
    inline-size: 120px;
    inset-block-start: calc(50% - 0.5px);
    background-color: #E1E3ED;
    content: "";
  }

  &:before {
    inset-inline-start: -135px;
  }

  &:after {
    inset-inline-end: -135px;
  }
}

.disclaimer {
  margin-block-start: 10px;
  font-style: italic;
  text-align: start;
}

.email {
  margin-block-end: 20px;
}

.submit {
  min-inline-size: 390px;
  margin-block-start: 30px;
}

.actions {
  margin-block-start: 30px;
  display: flex;
  gap: 24px;

  .submit {
    margin-block-start: 0;
    min-inline-size: 0;
  }
}

.back {
  inline-size: 123px;
}

.age {
  margin-block-start: 20px;
}

.disclaimer {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #8698B7;
  margin-block-start: 4px;
  align-self: flex-start;
}
