.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-block: 0 20px;
}

.stores {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.store {
  min-inline-size: calc(33.33% - 13.33px);
  inline-size: calc(33.33% - 13.33px);
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-block-start-width: 10px;
  border-radius: 12px;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 15px;
  min-block-size: 205px;

  &.active {
    border-block-start-color: #80C8C1;
  }
}

.name {
  font-weight: 600;
  text-decoration: none;
  color: inherit;
  margin-block: 0 16px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-block: 0;
}

.actions {
  display: flex;
  margin-block-start: auto;
}

.switch {
  margin-inline-start: auto;
}

.switch-field {
  inline-size: 48px;
  block-size: 24px;
  background: #F6F6F8;
  border-radius: 1000px;
  position: relative;

  &:before {
    position: absolute;
    inset-inline-start: 0;
    inset-block: 0;
    inline-size: 50%;
    border-radius: 50%;
    background: #B8B8B8;
    box-shadow: 0px 4px 4px rgba(171, 165, 165, 0.1);
    content: "";
  }

  .active & {
    &:before {
      background-color: #80C8C1;
      inset-inline-start: auto;
      inset-inline-end: 0;
    }
  }
}

.checkbox {
  position: absolute;
  inset-inline-start: -9999px;
}

.cta {
  color: #80C8C1;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: center;
}
