.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.card {
  background: #FFFFFF;
  border: 1px solid #D3DCEC;
  border-radius: 12px;
}

.toggle {
  margin: 30px;
}

.calendar {
  :global(.react-datepicker) {
    margin-block-start: 0;
    inline-size: 100%;
    border: 0;
    position: static;
  }

  :global(.react-datepicker__month-container) {
    float: none;
  }

  :global(.react-datepicker__header) {
    padding: 0;
    background: none;
    border-block-end: 0;
    text-align: start;
    position: static;
  }

  :global(.react-datepicker__day-names) {
    display: flex;
    border-block-start: 1px solid #E1E3ED;
    margin-block-end: 0;
  }

  :global(.react-datepicker__day-name) {
    inline-size: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    padding-block: 7px 10px;
    margin: 0;

    &:not(:last-child) {
      border-inline-end: 1px solid #E1E3ED;
    }
  }

  :global(.react-datepicker__month) {
    margin: 0;
  }

  :global(.react-datepicker__week) {
    display: flex;
    border-block-start: 1px solid #E1E3ED;
  }

  :global(.react-datepicker__day) {
    inline-size: 100%;
    min-block-size: 110px;
    margin: 0;
    display: flex;
    border-radius: 0;
    cursor: default;

    &:not(:last-child) {
      border-inline-end: 1px solid #E1E3ED;
    }

    &:hover {
      background: transparent;
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    background-color: transparent;
    color: inherit;
  }
}

.header {
  padding-inline: 30px;
  padding-block-end: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.month {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 38px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  padding: 0;
}

.day {
  display: flex;
  flex-direction: column;
  min-inline-size: 100%;
  padding: 15px;
  text-align: start;

  :global(.react-datepicker__day--today) & {
    background: rgba(128, 200, 193, 0.2);
  }
}

.date {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #31395F;
  position: relative;
  align-self: flex-start;

  :global(.react-datepicker__day--today) & {
    &:before {
      position: absolute;
      content: "";
      inset-inline-start: 50%;
      inset-block-start: 50%;
      inline-size: 27px;
      block-size: 27px;
      background: rgba(128, 200, 193, 0.4);
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.past {
  background: rgba(225, 227, 237, 0.4);
}

.hours {
  display: flex;
  flex-direction: column;
  margin-block-start: 15px;
}

.hour {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &.default {
    opacity: 0.3;
  }
}
