.form {
  display: flex;
  flex-direction: column;
  margin-block-end: 100px;
}

.get-upload {
  display: flex;
  gap: 20px;
  align-items: center;
}

.fields {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  inline-size: 100%;

  + .fields {
    margin-block-start: 20px;
  }
}

.field.field {
  inline-size: calc(33.33% - 13.333px);
}

.spacer {
  min-inline-size: 100%;
}

.submit {
  align-self: center;
  margin-block-start: 40px;
}

.parent-select {
  inline-size: 400px;
  margin-block: 20px;
}

.external-source {
  margin-block-start: 20px;
}

.external-field.external-field {
  max-inline-size: 150px;
  min-inline-size: 150px;
}

.external-source {
  display: flex;
  gap: 20px;
  max-inline-size: 400px;
}

.external-source-field {
  margin-block-start: -5px;
}
