.wrapper {
  background-color: #fff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  padding-block: 30px;
  position: fixed;
  inset-block-start: 70px;
  inset-inline: 0;
  inset-block-start: 70px;
  border-block-start: 1px solid #E1E3ED;
  z-index: 1;

  &.summary {
    padding-block: 20px;
  }
}

.searchContainer {
  display: flex;
  gap: 30px;
}

.flex1 {
  flex: 1;
}

.container {
  max-inline-size: 960px + 180px;
  margin-inline: auto;
  padding-inline: 90px;

  .summary & {
    max-inline-size: 1386px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-block: 0 30px;
  white-space: nowrap;

  .summary & {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-block: 0;
  }
}

.fields {
  display: flex;
  inline-size: 100%;
  gap: 19px;

  > * {
    inline-size: 100%;
  }
}

.search {
  position: relative;
  margin-block-end: 14px;

  .summary & {
    margin-block-end: 0;
    min-inline-size: 200px;
    max-inline-size: 200px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    inset-block-start: 50%;
    transform: translateY(-50%);
    inset-inline-start: 12px;
  }
}

.search-field {
  background: #FFFFFF;
  border: 1px solid #62769D;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  block-size: 48px;
  inline-size: 100%;
  padding-inline: 43px 16px;

  .summary & {
    block-size: 40px;
    padding: 7px 16px 7px 40px;
  }

  &:not(:focus) {
    font-style: italic;
    color: rgba(96, 112, 153, 0.5);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.selected {
  display: flex;
  gap: 10px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pill {
  background: #F6F6F8;
  border-radius: 40px;
  block-size: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.pill-value {
  font-weight: 600px;
  color: #232323;
  opacity: 0.86;
}

.reset-button-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 4px;
}

.reset-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: start;
}

.reset {
  min-block-size: 48px;
  max-inline-size: 153px;
  inline-size: 100%;
  padding: 12px 0px;
}
